import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addMember,
  clearChat,
  createGroupChat,
  getGroupHistory,
  getGroupList,
  leaveGroup,
  removeMember,
  updateGroupChat,
} from "../../api/chat/groupChat";
import toast from "react-hot-toast";
import { getUsersList } from "../../api/chat/chat";

export const useGroupChatList = (
  userId,
  workId,
  subId = null,
  enabled,
  selectData = (data) => data.data.data
) => {
  return useQuery({
    queryKey: ["group","list","chats", userId, workId, subId],
    queryFn: async () => {
      return await getGroupList(userId, workId, subId);
    },
    select: selectData,
    enabled: !!userId && !!workId && enabled,
    // refetchOnWindowFocus: true,
  });
};

export const useCreateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group) => {
      return await createGroupChat(group);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries({ queryKey: ["group", "list"] });
      toast.success("Group created successfully");
    },
    onError: () => {
      toast.error("Failed to create group");
    },
  });
};
export const useUpdateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group) => {
      const { id: groupId, name, description, groupAvatar } = group;
      return await updateGroupChat(groupId, {
        name,
        description,
        groupAvatar
      });
    },
    onSuccess: async (data) => {
      await queryClient?.invalidateQueries(
        { queryKey: ["group","list","chats"] },
        { queryKey: ["group","list","users"] }
      );
      // await queryClient?.invalidateQueries([{ queryKey: ["group","list","chats"] }]);
      toast.success("Group details updated successfully");
    },
    onError: () => {
      toast.error("Failed to update group");
    },
  });
};
export const useAddMemberGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group) => {
      const { id: groupId, users } = group;
      return await addMember(groupId, {
        users,
      });
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries({ queryKey: ["group","list","users"] });
      toast.success("Group member added successfully");
    },
    onError: () => {
      toast.error("Failed to add member in group");
    },
  });
};

export const useLeaveGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group) => {
      const { id: groupId, userId } = group;
      return await leaveGroup(groupId, userId);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries({ queryKey: ["group","list","users"] });
      toast.success("you left the group");
    },
    onError: () => {
      toast.error("Failed to leave group");
    },
  });
};
export const useRemoveMemberGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group) => {
      const { id: groupId, userId } = group;
      return await removeMember(groupId, userId);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries({ queryKey: ["group","list","users"] });
      toast.success(`you removed member from the group`);
    },
    onError: () => {
      toast.error("Failed to remove user group");
    },
  });
};
export const useClearChatGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group) => {
      const { id: groupId, userId } = group;
      return await clearChat(groupId, userId);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries({
        queryKey: ["group","chat","history"],
      });
      toast.success(`chat is cleared`);
    },
    onError: () => {
      toast.error("Failed to clear chat ");
    },
  });
};

export const useGetGroupChatHistory = (groupId, userId, page, limit) => {
  return useQuery({
    queryKey: ["group","chat","history", groupId, userId, page, limit],
    queryFn: async () => {
      return await getGroupHistory(groupId, userId, page, limit);
    },
    select: (data) => data.data.data || [],
    enabled: !!groupId && !!userId,
  });
};

export const useGetGroupUsersList = (
  groupId,
  enabled,
  selectData = (data) => data.data.data
) => {
  return useQuery({
    queryKey: ["group","list","users", groupId],
    queryFn: async () => {
      return await getUsersList(groupId);
    },
    select: selectData,
    enabled: !!groupId && enabled,
    // refetchOnWindowFocus: true,
  });
};
