import { useRef, useState } from "react";

const EditableTextArea = ({ disable= false,value, onChange, wordLimit = 50 }) => {
  const [editMode, setEditMode] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(value);
  const textAreaRef = useRef(null);

  const handleContentChange = (value) => {
    const inputText = value;
    const words = inputText.trim().split(/\s+/);
    if (words.length <= wordLimit) {
        setTextAreaValue(inputText);
      return;
    } else {
      const limitedWords = words.slice(0, wordLimit).join(" ") + " ";
      setTextAreaValue(limitedWords);
    }
  };

  const handleSave = () => {
    onChange(textAreaValue);
    setEditMode(false); // Exit edit mode
  };

  const handleClick = () => {
    if (disable) return
    if (!editMode) {
      setEditMode(true);
      setTimeout(() => textAreaRef.current.focus(), 0);
    }
  };

  const words = textAreaValue.trim().split(/\s+/).filter(Boolean);
  const charCount = words.length;

  return (
    <div className="relative w-full">
      {editMode ? (
        <textarea
          className="p-2 flex-1 w-full"
          value={textAreaValue}
          onChange={(e) => handleContentChange(e.target.value)}
          onBlur={handleSave}
          ref={textAreaRef}
        />
      ) : (
        <div
          onClick={handleClick}
          className="flex w-full flex-1 gap-2 p-2 items-center"
        >
          <span className="text-debatePalette-title">{value}</span>
        </div>
      )}
      {editMode && (
        <div className="absolute bottom-2 right-3">
          {charCount} / {wordLimit}
        </div>
      )}
    </div>
  );
};

export default EditableTextArea;

