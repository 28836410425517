import { Card, CardBody } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import NoData from "../../Components/Functionality/NoData";
import CitationTagListPopup from "../../Components/Popup/CitationTagListPopup";
import MotionCard from "../../Components/UI/Cards/MotionCard";
import Container from "../../Components/UI/Container";
import SearchField from "../../Components/UI/Inputs/SearchField";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import useDebounce from "../../Services/useDebounce";
import {
  useGetCategoryList,
  useGetMotionList,
} from "../../Web-Hooks/Motion/use-motion";
import ShareDebate from "../Posts/ShareDebate";
import SortByMotion from "./SortByMotion";

const FindMotion = () => {
  const [query, setQuery] = useSearchParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState(query?.get("search") || "");
  const { user } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const [citationPopup, setCitationPopup] = useState(false);
  const [motionPopup, setMotionPopup] = useState(null);
    const [motion, setMotion] = useState(null);
    const [shareDebatePopup, setShareDebatePopup] = useState(false);
  const [optionType, setOptionType] = useState({
    label: "Most Recent",
    value: "Most Recent",
    type: "Most Recent",
  });

  const { data: motions, isLoading: isMotionLoading } = useGetMotionList({
    loggedInUserId: user?.userId,
    workspaceId: selectedWorkspace?.workSpaceId,
    subworkspaceId:
      optionType?.type === "WorkspaceWise"
        ? ""
        : optionType?.type === "SubworkspaceWise"
        ? optionType?.value
        : "",
    type: optionType?.type,
  });

  const [filteredMotion, setFilteredMotion] = useState(motions);

  const DeferredSearch = useDebounce(search, 500);

  useEffect(() => {
    if (DeferredSearch) {
      const search = DeferredSearch.toLowerCase();
      const filtered = motions?.filter((motion) => {
        return motion?.motion?.toLowerCase().includes(search);
      });
      setFilteredMotion(filtered);
    } else {
      setFilteredMotion(motions);
    }
  }, [DeferredSearch, motions]);

  useEffect(()=>{
    setQuery({search})
  },[search, setQuery])

  const { data: categoriesCountList, isLoading: isCategoryLoading } =
    useGetCategoryList(selectedWorkspace?.workSpaceId);
  const { subWorkspaceList } = useSubWorkspace();

  const nestedOptions = [
    { label: "Most Recent", value: "Most Recent", type: "Most Recent" },
    { label: "Most Popular", value: "Most Popular", type: "Most Popular" },
    {
      label: "Category",
      options: categoriesCountList?.map((category) => ({
        label: category?.categoryName,
        value: category?.categoryName,
        count: category?.motionCount,
        type: category?.categoryName,
      })),
    },
    {
      label: "Forums",
      options: subWorkspaceList?.map((subWorkspace) => ({
        label: subWorkspace?.subWorkSpaceName,
        value: subWorkspace?.subWorkSpaceId,
        type: "SubworkspaceWise",
      })),
    },
    { label: "All Forum", value: "WorkspaceWise", type: "WorkspaceWise" },
  ];

  const handleDebateView = (debate) => {
    navigate(`/debate/${debate.id}`, { state: { debate: debate } });
  };

  return (
    <Container>
      <CitationTagListPopup
        debate={motionPopup}
        citationPopup={citationPopup}
        setCitationPopup={setCitationPopup}
        setDebate={setMotionPopup}
        type={"motion"}
      />
      <ShareDebate
        shareDebatePopup={shareDebatePopup}
        setShareDebatePopup={setShareDebatePopup}
        debate={{
          motionId: motion?.motionId,
          motion: motion?.motion,
          createdAt: motion?.createdAt,
          subworkspaceId: motion?.subworkspaceId,
          subworkspaceName: motion?.subworkspaceName,
          workspaceId: selectedWorkspace?.workspaceId,
          workspaceName: selectedWorkspace?.workspaceName,
        }}
        setDebate={setMotion}
        enabled={shareDebatePopup}
        type="motion"
      />
      <div className="flex md:flex-row flex-col gap-3 justify-between w-full">
        <div className="w-4/4 md:w-2/4">
          <SearchField
            id="motion-search"
            name="motion-search"
            variant="rounded"
            search={search}
            setSearch={setSearch}
          />
        </div>
        {!isCategoryLoading && (
          <SortByMotion
            name={"motion-sortBy"}
            id={"motion-sortBy"}
            Options={nestedOptions}
            setOption={setOptionType}
            defaultValue={optionType}
          />
        )}
      </div>
      {isMotionLoading ? (
        <SkeletonForMotion count={4} />
      ) : (
        <div className="flex flex-col gap-4 w-full h-full">
          {filteredMotion?.length !== 0 ? (
            filteredMotion?.map((motion, i) => (
              <MotionCard
                motion={motion}
                key={motion?.motionId}
                handleDebateView={(motion) => handleDebateView(motion)}
                setMotionPopup={setMotionPopup}
                setCitationPopup={setCitationPopup}
                setShareDebatePopup={setShareDebatePopup}
                setMotion={setMotion}
              />
            ))
          ) : (
            <NoData message={"No Motion Found"} size={"w-full h-[80%]"} />
          )}
        </div>
      )}
    </Container>
  );
};

export default FindMotion;

const SkeletonForMotion = ({ count = 2 }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Card
          key={index}
          shadow={false}
          className="w-full py-4 rounded-none border-b-2 border-gray-300 flex-col sm:flex-row items-center sm:items-stretch animate-pulse"
        >
          <CardBody className="flex lg:flex-row flex-col items-start w-full justify-between py-1 px-0 gap-3">
            <div className="flex items-center gap-3">
              {/* MotionChip Skeleton */}
              <div className="bg-gray-300 rounded-md h-16 w-40"></div>

              <div className="flex flex-col">
                {/* Motion Text Skeleton */}
                <div className="flex gap-2">
                  <div className="bg-gray-300 h-4 w-24 mt-1 rounded"></div>
                  <div className="bg-gray-300 h-4 w-24 mt-1 rounded"></div>
                </div>

                {/* SubworkspaceName Text Skeleton */}

                {/* Hidden MotionActions Skeleton */}
                <div className="lg:flex gap-2 items-center hidden mt-2">
                  <div className="bg-gray-300 h-5 w-60 rounded"></div>
                </div>
              </div>
            </div>

            <div className="flex lg:self-end items-center justify-between w-full lg:w-auto">
              <div className="flex gap-0 xs:gap-1 sm:gap-2 items-center lg:hidden">
                {/* MotionActions Skeleton */}
                <div className="bg-gray-300 h-5 w-24 rounded"></div>
              </div>

              <div className="flex gap-4 items-center">
                {/* Time Skeleton */}
                <div className="bg-gray-300 h-4 w-20 rounded"></div>
              </div>
            </div>
          </CardBody>
        </Card>
      ))}
    </>
  );
};
