import { EyeIcon } from "@heroicons/react/24/outline";
import { Card, CardBody, Chip, Tooltip } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getLocalTime } from "../../../Utils/Helper";
import MotionActions from "../../Functionality/MotionActions";
import MotionChip from "../Chip/MotionChip";

const MotionCard = ({
  motion,
  handleDebateView,
  setCitationPopup,
  setMotionPopup,
  setShareDebatePopup,
  setMotion
}) => {
  const navigate = useNavigate();
  const handleCreateDebate = (e) => {
    e.stopPropagation();
    const Fields = motion?.category?.map((cat) => cat.fieldName);
    const jointFields = Fields.join(";");
    navigate(`/create-debate?from=Motion&motionId=${motion?.motionId}`, {
      state: {
        selectedDebate: {
          MotionOrClaim: motion?.motion,
          Fields: jointFields,
        },
      },
      replace: true,
    });
  };

  const motionCategories = motion?.category
    ?.slice()
    .sort((a, b) => a.fieldName.localeCompare(b.fieldName))
    .map((cat) => cat);
  return (
    <Card
      shadow={false}
      className="w-full py-4 cursor-pointer rounded-none border-b-2 border-debatePalette-buttonBorder flex-col sm:flex-row items-center sm:items-stretch "
      onClick={handleCreateDebate}
    >
      <CardBody className="flex lg:flex-row flex-col items-start w-full justify-between py-1 px-0 gap-3">
        <div className="flex lg:flex-row flex-col items-center gap-3 w-full">
          <MotionChip item={motionCategories} />
          <div className="flex flex-col flex-1">
            <p className="text-lg font-medium flex gap-1 text-debatePalette-title">
              <Tooltip
                content={motion?.motion}
                className="max-w-sm bg-secondary text-debatePalette-background"
              >
                <span className="line-clamp-1">{motion?.motion}</span>
              </Tooltip>
              <span>-</span>
              <span className="text-primary">{motion?.subworkspaceName}</span>
            </p>
            <div className="lg:flex gap-2 justify-between items-center hidden">
              <div className="lg:flex gap-2 items-center">
                <MotionActions
                  motion={motion}
                  setCitationPopup={setCitationPopup}
                  setMotionPopup={setMotionPopup}
                  setSharePopup={setShareDebatePopup}
                  setMotion={setMotion}
                />
              </div>
              <span className="shrink-0">
                {getLocalTime(motion?.createdAt)}
              </span>
            </div>
          </div>
        </div>
        <div className="flex lg:self-end items-center justify-between w-full lg:hidden">
          <div className="flex flex-col xs:flex-row justify-between w-full">
            <div className="flex gap-0 xs:gap-1  sm:gap-2 items-center ">
              <MotionActions
                motion={motion}
                setCitationPopup={setCitationPopup}
                setSharePopup={setShareDebatePopup}
                setMotionPopup={setMotionPopup}
                setMotion={setMotion}
              />
            </div>
            <span className="shrink-0">{getLocalTime(motion?.createdAt)}</span>
          </div>
          <div className="flex gap-4">
            {motion?.isActive && (
              <>
                <Chip color="green" value="Active" />{" "}
                <EyeIcon
                  onClick={() => handleDebateView(motion)}
                  className="w-6 h-6 text-debatePalette-title cursor-pointer"
                />
              </>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default MotionCard;
