import { useRef, useState } from "react";

const EditableInput = ({ disable = false, value, onChange, charLimit = 30 }) => {
    const [editMode, setEditMode] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const inputRef = useRef(null);
  
    const handleInputChange = (e) => {
      const { value } = e.target;
        setInputValue(value);
    };
  
    const handleSave = () => {
      onChange(inputValue);
      setEditMode(false); // Exit edit mode
    };
  
    const handleClick = () => {
      if (disable) return
      if (!editMode) {
        setEditMode(true);
        setTimeout(() => inputRef.current.focus(), 0);
      }
    };
  
    if (editMode) {
      return (
        <div className="relative flex w-full flex-1 gap-2 p-2 items-center">
          <input
            className="p-2 flex-1 w-full"
            type="text"
            maxLength={charLimit}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleSave}
            ref={inputRef}
          />
          <span className="absolute  right-3">{`${charLimit - inputValue.length}`}</span>
        </div>
      );
    } else {
      return (
        <div onClick={handleClick} className="flex w-full flex-1 gap-2 p-2 items-center">
          <span className="text-debatePalette-title">{value}</span>
        </div>
      );
    }
  };
  
export default EditableInput  
