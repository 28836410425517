import {
  ArrowDownTrayIcon,
  DocumentDuplicateIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../Context/AuthContext";
import { useFileViewer } from "../../Context/FileViewerContext";
import { handleDownloadFile } from "../../Utils/Helper";
import ImageOrInitials from "./ImageOrInitials";

const FilePopup = () => {
  const { user: loggedInUser } = useAuth();
  const { file, resetFile } = useFileViewer();
  // const [zoomLevel, setZoomLevel] = useState(1); // initial zoom level is 1

  // const handleZoomIn = () => {
  //   setZoomLevel(zoomLevel + 0.1); // increase zoom level by 0.1
  // };

  // const handleZoomOut = () => {
  //   setZoomLevel(zoomLevel - 0.1); // decrease zoom level by 0.1
  // };

  const {
    fileUrl,
    fileType,
    fileName,
    fileUploadedAt,
    channelName,
    createdBy,
    creatorImage,
  } = file;

  const isGoogleDocsViewable = [
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "document",
  ].includes(fileType?.toLowerCase());

  const isVideosType = [
    "mp4",
    "mov",
    "wmv",
    "avi",
    "mkv",
    "mpg",
    "mpeg",
    "webm",
    "webp",
    "flv",
    "3gp",
    "video",
  ].includes(fileType?.toLowerCase());

  const isImageType = [
    "jpeg",
    "jpg",
    "png",
    "gif",
    "bmp",
    "tiff",
    "webp",
    "svg",
    "psd",
    "ai",
    "eps",
    "image",
  ].includes(fileType?.toLowerCase());

  useEffect(() => {

    if(!fileUrl && !fileType) return

    const modalElement = document.getElementById("file-viewer");
    const parentElement = modalElement.parentElement.parentElement;

    if (modalElement && parentElement) {
      if (!!(fileUrl && fileType)) {
        parentElement.classList.add("custom-parent-class");
      } else {
        parentElement.classList.remove("custom-parent-class");
      }
    }
  }, [fileUrl, fileType]);


  return (
    <Dialog
    prefix="helloworls"
      open={!!(fileUrl && fileType)}
      handler={resetFile}
      size="xl"
      id="file-viewer"
      className="bg-secondary bg-opacity-35 backdrop-blur-sm h-full w-full overflow-y-auto"
      style={{
        zIndex: 99999
      }}
    >
      <DialogHeader className="w-full flex justify-end items-center h-[10%]">
        <div className="flex items-center rounded-md w-full">
          {/* Avatar */}
          <ImageOrInitials
            imageSrc={creatorImage || loggedInUser?.userImage}
            initials={createdBy || loggedInUser?.userName}
          />

          {/* File details */}
          <div className="ml-4">
            <div className="font-semibold text-debatePalette-main text-sm">
              {createdBy || loggedInUser?.userName}
              {channelName ? ` - ${channelName}` : ""}
            </div>
            <div className="text-xs text-debatePalette-buttonBorder">
              {fileUploadedAt} – {fileName}
            </div>
          </div>
        </div>
        <Button
          variant="text"
          className="text-debatePalette-buttonBorder hover:text-debatePalette-background hover:bg-debatePalette-title"
          onClick={resetFile}
        >
          <XMarkIcon className="h-6 w-6" />
        </Button>
      </DialogHeader>
      <DialogBody className="flex justify-center items-center h-[80%] w-full rounded-lg">
        {isGoogleDocsViewable ? (
          <embed
            title="Google Docs Viewer"
            src={`https://docs.google.com/gview?url=${encodeURIComponent(
              fileUrl
            )}&embedded=true`}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        ) : isImageType ? (
          <div className="h-[60%] w-full lg:w-[60%] flex justify-center">
            <img
              src={fileUrl}
              alt="file"
              className="object-contain h-full"
              // style={{ transform: `scale(${zoomLevel})` }} // apply zoom level to image
            />
          </div>
        ) : isVideosType ? (
          <video controls className="h-[60%] w-full lg:w-[60%] ">
            <source src={fileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div>
            <p>File type is not supported for viewing. You can download it:</p>
            <a href={fileUrl} download>
              Download File
            </a>
          </div>
        )}
      </DialogBody>
      <DialogFooter className="rounded-b-lg h-[10%] flex justify-end">
        {/* <div className="flex gap-3">
            <Button
              variant="text"
              className="text-debatePalette-buttonBorder hover:text-debatePalette-background hover:bg-debatePalette-title"
              onClick={handleZoomIn}
            >
            
            </Button>
            <Button
              variant="text"
              className="text-debatePalette-buttonBorder hover:text-debatePalette-background hover:bg-debatePalette-title"
              onClick={handleZoomOut}
            >
            </Button>
          </div> */}
        <div className="flex gap-3">
          <Button
            variant="text"
            className="text-debatePalette-buttonBorder hover:text-debatePalette-background hover:bg-debatePalette-title"
            onClick={() => handleDownloadFile(fileUrl, fileName)}
          >
            <ArrowDownTrayIcon className="w-6 h-6" />
          </Button>
          <Button
            variant="text"
            className="text-debatePalette-buttonBorder hover:text-debatePalette-background hover:bg-debatePalette-title"
            onClick={() => {
              navigator.clipboard.writeText(fileUrl);
              toast.success("Copied to clipboard");
            }}
          >
            <DocumentDuplicateIcon className="w-6 h-6" />
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default FilePopup;
