import React from "react";
import AddCitationInTags from "../../Pages/Home/AddCitationInTags";
import NoData from "../Functionality/NoData";
import CitationCard from "../UI/Cards/CitationCard";
import LoadingSpinner from "../UI/LoadingSpinner";

const CitationTagList = ({
  motion,
  type,
  citationList,
  isCitationListLoading,
  disableAddCitation,
  side,
  argumentId,
  argumentType,
  round,
  userArguments,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-4 h-72 p-2 overflow-y-auto">
        {!isCitationListLoading ? (
          citationList?.length > 0 ? (
            citationList?.map((citation) => (
              <CitationCard type={type} citation={citation} key={citation?.citationId} />
            ))
          ) : (
            <NoData message={"No Citation Tag Found"} size={"w-full h-full"} />
          )
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <AddCitationInTags
        round={round}
        disable={disableAddCitation}
        debate={motion}
        type={type}
        side={side}
        argumentId={argumentId}
        argumentType={argumentType}
        userArguments={userArguments}
      />
    </div>
  );
};

export default CitationTagList;
