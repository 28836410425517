import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { Button } from "@material-tailwind/react";
import React, { useRef, useState } from "react";
import { useSocket } from "../../../Context/SocketContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import MessageFileUpload from "./MessageFileUpload";
import EmojiPicker from "emoji-picker-react";
import { FaceSmileIcon } from "@heroicons/react/24/outline";

function MessageInput({ sender, receiver }) {
  const textAreaRef = useRef(null);
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // State to toggle emoji picker
  const { sendMessage, isTyping } = useSocket();
  const forumOnly = process.env.REACT_APP_FORUM_WISE === "true";

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  const handleInput = () => {
    const textArea = textAreaRef.current;
    textArea.style.height = "auto";
    textArea.style.height = `${textArea.scrollHeight}px`;
  };

  const handleSend = () => {
    isTyping(sender?.userId, receiver?.id, false);

    if (!message?.trim()) return;
    const Message = {
      type: "text",
      content: {
        text: message,
      },
    };
    setMessage("");
    textAreaRef.current.style.height = "auto"; // Reset height
    textAreaRef.current.setAttribute("rows", 1); // Reset rows
    sendMessage(
      sender?.userId,
      receiver?.id,
      Message,
      selectedWorkspace?.workSpaceId,
      forumOnly ? selectedSubWorkspace?.subWorkSpaceId : ""
    );
  };

  const onEnterPress = (e) => {
    if (e.target.value === "") {
      isTyping(sender?.userId, receiver?.id, false);
      return;
    }
    isTyping(sender?.userId, receiver?.id, true);

    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      setShowEmojiPicker(false);
      handleSend();
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji); // Append emoji to message
    textAreaRef.current.focus();
  };

  return (
    <div className="flex items-end bg-gray-100 border-secondary border rounded-lg px-4 py-2 shadow-md mx-auto relative">
      <MessageFileUpload sender={sender} receiver={receiver} />

      {/* Emoji Picker Toggle Button */}
      <Button
        variant="text"
        className="text-gray-500 p-1 text-xl mr-3 focus:outline-none"
        onClick={() => setShowEmojiPicker((prev) => !prev)}
      >
        <FaceSmileIcon className="size-6 text-secondary" />
      </Button>

      {/* Emoji Picker */}
      {showEmojiPicker && (
        <div className="absolute bottom-12 left-0 z-10">
          <EmojiPicker onEmojiClick={(emojiObject) => handleEmojiClick(emojiObject)} />
        </div>
      )}

      {/* Expanding Textarea */}
      <textarea
        rows={1}
        resize="false"
        placeholder="Your Message"
        onInput={handleInput}
        onChange={handleChange}
        ref={textAreaRef}
        className="flex-1 !border-0 min-h-full focus:border-transparent resize-y border-none outline-none bg-transparent text-base max-h-36 overflow-y-auto py-1"
        style={{ height: "auto" }}
        id="message-input"
        value={message}
        onKeyDown={onEnterPress}
      />

      {/* Send Button */}
      <Button
        variant="text"
        onClick={()=>{
          setShowEmojiPicker(false);
          handleSend();
        }}
        className="text-gray-500 p-1 text-xl ml-3 focus:outline-none"
        disabled={!message}
        id="message-send"
      >
        <PaperAirplaneIcon className="size-6 text-secondary" />
      </Button>
    </div>
  );
}

export default MessageInput;
