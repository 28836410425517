import React from "react";
import { useAuth } from "../../Context/AuthContext";
import { useSearchParams } from "react-router-dom";
import { useGetMotionCitationList } from "../../Web-Hooks/Motion/use-motion";
import { ReactComponent as Copy } from "../../Assets/Svg/Copy.svg";
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import NoData from "../Functionality/NoData";
import LibraryCard from "../../Pages/Library/LibraryCard";
import { useWorkspace } from "../../Context/WorkspaceContext";

const SelectCitationFromMotionList = ({
  setCitationPopup,
  setSelectedCitation,
  motionPopup,
  setMotionPopup,
}) => {
  const { user } = useAuth();
  const [query] = useSearchParams();
  const motionId = query?.get("motionId");
  const {selectedWorkspace} = useWorkspace();
  const { data: motionCitationList } = useGetMotionCitationList(
    motionId,
    user?.userId
  );

  const motionUploadFilePath = `${process.env.REACT_APP_MOTION_UPLOAD}/${selectedWorkspace?.workSpaceId}`

  const Actions = [
    {
      SVG: Copy,
      title: "Copy",
      handleClick: (citation) => {
        setSelectedCitation({
          citationUrl: `${process.env.REACT_APP_AZURE_STORAGE_BASE_URL}/debatemodule/${motionUploadFilePath}/${citation?.refUrl}`,
          citationNote: citation?.note,
          citationType: citation?.refFileType,
          citationSize: citation?.size,
        });
        setCitationPopup(true);
      },
    },
  ];

  return (
    <Dialog open={motionPopup} handler={setMotionPopup}>
      <DialogHeader className="flex items-center justify-between">
        <Typography variant="h4">Select Citation</Typography>
        <Button
          variant="text"
          className="p-2"
          onClick={() => {
            setMotionPopup(false);
          }}
        >
          <XMarkIcon className="h-6 w-6  text-debatePalette-title" />
        </Button>
      </DialogHeader>
      <DialogBody className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 min-h-[400px] h-auto">
          {motionCitationList?.length > 0 ? (
            motionCitationList?.map((citation, i) => (
              <LibraryCard
                Actions={Actions}
                key={citation.citationId}
                Citation={{
                  ...citation,
                  refFileType: citation?.citationFileType,
                  refUrl: `${process.env.REACT_APP_AZURE_STORAGE_BASE_URL}/debatemodule/${motionUploadFilePath}/${citation?.citationUrl}`,
                  note: citation?.citationNote,
                }}
              />
            ))
          ) : (
            <div className="h-[400px]">
              <NoData
                message={"No citations found"}
                isNoData={true}
                size={"w-full h-[80%]"}
              />
            </div>
          )}
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default SelectCitationFromMotionList;
