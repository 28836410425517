import React, { Fragment } from "react";
import { ReactComponent as DoubleChecked } from "../../../Assets/Svg/DoubleChecked.svg";
import { ReactComponent as SingleChecked } from "../../../Assets/Svg/SibgleChecked.svg";
import moment from "moment";
import { Typography } from "@material-tailwind/react";
import DocImage from "../../../Assets/Images/doc.png";
import PDFImage from "../../../Assets/Images/pdf.png";
import URLImage from "../../../Assets/Images/url.png";
import VIDImage from "../../../Assets/Images/video.png";
import { getUserImageUrl } from "../../../Utils/Helper";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";

const MessageSidebarCard = ({ item, pathname }) => {
  const renderContent = () => {
    switch (item?.lastMessage?.type) {
      case "text":
        return (
          <span className="flex items-center gap-2">
            {item?.lastMessage?.content?.text}
          </span>
        );

      case "image":
        return <span className="flex items-center gap-2">Photo</span>;

      case "doc":
        return (
          <span className="flex items-center gap-2">
            {" "}
            <img
              src={DocImage}
              className="inline max-w-[15px] max-h-[15px]"
              alt="doc-img"
            />{" "}
            Document
          </span>
        );

      case "pdf":
        return (
          <span className="flex items-center gap-2">
            {" "}
            <img
              src={PDFImage}
              className="inline max-w-[15px] max-h-[15px]"
              alt="pdf-img"
            />{" "}
            PDF
          </span>
        );

      case "video":
        return (
          <span className="flex items-center gap-2">
            {" "}
            <img
              src={VIDImage}
              className="inline max-w-[15px] max-h-[15px]"
              alt="video-img"
            />{" "}
            Video
          </span>
        );

      case "url":
        return (
          <span className="flex items-center gap-2">
            {" "}
            <img
              src={URLImage}
              className="inline max-w-[15px] max-h-[15px]"
              alt="url-img"
            />{" "}
            Link
          </span>
        );
      case "profile":
        return <span className="flex items-center gap-2">Profile shared</span>;
      case "motion":
        return <span className="flex items-center gap-2">Motion shared</span>;

      case "debate":
        return <span className="flex items-center gap-2">Debate shared</span>;
      default:
        return (
          <span className="flex items-center gap-2">
            Unsupported message type
          </span>
        );
    }
  };

  return (
    <Fragment>
      <ImageOrInitials
        imageSrc={getUserImageUrl(item?.id, item?.avatar)}
        initials={item?.username}
        classNameInitials={"text-xs"}
        size={"h-10 w-10"}
        showUserStatus={true}
        userStatus={item?.online}
      />
      <div className="flex flex-1 shrink-0 flex-col justify-between">
        <div className="flex justify-between items-center">
          <Typography
            color="white"
            className={
              pathname === `/message/${item?.id}`
                ? `bg-primary text-sm font-semibold truncate`
                : ` truncate text-sm`
            }
          >
            {item?.username}
          </Typography>{" "}
          <Typography
            color="white"
            variant="small"
            className="text-[11px] font-semibold normal-case"
          >
            {(() => {
              const timeAgo = moment(item.lastMessageTimestamp).fromNow(true);
              if (timeAgo.includes("minutes")) {
                return timeAgo.replace(" minutes", "m");
              } else if (timeAgo.includes("hours")) {
                return timeAgo.replace(" hours", "h");
              } else if (timeAgo.includes("days")) {
                return timeAgo.replace(" days", "d");
              }
              return timeAgo;
            })()}
          </Typography>{" "}
        </div>
        <div className="flex items-center justify-between">
          <Typography
            color="white"
            variant="small"
            className={
              pathname === `/message/${item?.id}`
                ? `bg-primary text-xs max-w-[130px] truncate`
                : `max-w-[130px] text-xs truncate`
            }
            style={{ width: "100%" }}
          >
            {renderContent()}
          </Typography>

          {item?.unreadCount === 0 || !item?.unreadCount ? (
            item?.isRead ? (
              <DoubleChecked className="w-4 text-debatePalette-background h-4" />
            ) : (
              <SingleChecked className="w-4 text-debatePalette-background h-4 " />
            )
          ) : (
            <div className="h-4 w-4 rounded-full flex items-center justify-center shrink-0 bg-red-500 text-debatePalette-background text-xs">
              {item?.unreadCount > 9 ? "9+" : item?.unreadCount}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default MessageSidebarCard;
