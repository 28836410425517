import { Button, Typography } from "@material-tailwind/react";
import React from "react";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import { useAuth } from "../../../Context/AuthContext";
import { useRemoveMemberGroup } from "../../../Web-Hooks/Chat/use-group-chat";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useSocket } from "../../../Context/SocketContext";

const Member = ({ member, groupDetails, setGroupDetails }) => {
  const { mutateAsync: removeMember, isPending: isRemoving } =
    useRemoveMemberGroup();
  const { user: loggedInUser } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { sendGroupMessage } = useSocket();

  const handleRemoveMember = async () => {
    try {
      const userToRemove = {
        id: groupDetails?.id,
        userId: member?.id,
      };
      await removeMember(userToRemove);

      const Message = {
        type: "user_removed",
        content: {
          removedByUserId: loggedInUser?.userId,
          removedByUserName: loggedInUser?.userName,
          userId: member?.id,
          userName: member?.username,
        },
      };
      sendGroupMessage(
        groupDetails?.id,
        loggedInUser?.userId,
        Message,
        selectedWorkspace?.workSpaceId,
        selectedSubWorkspace?.subWorkSpaceId
      );
      setGroupDetails((prev) => ({
        ...prev,
        users: prev?.users?.filter((item) => item !== member?.id),
      }))
    } catch (error) {
      console.log("Error occurred while removing member", error);
    }
  };

  const isCreator = groupDetails?.created_by === loggedInUser?.userId;

  return (
    <div className="flex items-center w-full justify-between gap-2">
      <div className="flex gap-3 items-center">
        <ImageOrInitials
          imageSrc={member?.avatar}
          initials={member?.username}
          size="h-10 w-10"
        />
        <div className="flex flex-col">
          <Typography className=" capitalize font-semibold text-debatePalette-title">
            {member?.username}
          </Typography>
          <Typography
            variant="small"
            className="text-xs capitalize text-debatePalette-title"
          >
            {groupDetails?.created_by === member?.id ? "Admin" : "Member"}
          </Typography>
        </div>
      </div>
      {isCreator && member?.id !== loggedInUser?.userId && (
        <div className="flex gap-3 items-center justify-center">
          <Button
            variant="text"
            color="red"
            size="xs"
            className="text-xs"
            onClick={handleRemoveMember}
            loading={isRemoving}
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};
const GroupMemberPopup = ({ groupDetails, setGroupDetails, groupMembers }) => {
  return (
    <div className="flex flex-col items-start gap-3 justify-center">
      <Typography
        variant="lead"
        className="text-debatePalette-title font-semibold"
      >
        Members
      </Typography>
      {groupMembers?.map((member) => (
        <Member key={member?.id} setGroupDetails={setGroupDetails} member={member} groupDetails={groupDetails} />
      ))}
    </div>
  );
};

export default GroupMemberPopup;
